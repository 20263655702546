import React from "react"
import H1 from "../../components/H1"
import Alert from "../../components/Alert"
import * as styles from "./styles.module.css"

const One = () => {
  return (
    <section className={styles.one}>
      <H1 text="404 Error"></H1>

      <div className={styles.grid}>
        <div>
          <Alert
            data={{
              type: "notice",
              text:
                "Page not found, there is no valid page located at this Url.",
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default One
